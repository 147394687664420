import { IEnvironment, IFlags } from "@air-gmbh/util/environment";
import { LogLevel } from "angular-auth-oidc-client";

// Flags
const flags: IFlags = {
  // Expects Record<string, boolean>
  features: {
    auth: true,
    stable: false,
    airboardComparing: false,
    airboardRedesign: true,
    deletePartnerMaritalStatus: false,
    civilServantPensioner: true,
    editPageLayout: false,
    assetRedesign: false,
  },
  // Expects Record<string, any>
  variants: {},
};

export const environment: IEnvironment = {
  environmentName: "FCB Integration",
  production: false,
  locale: {
    language: "de-DE",
    fallbackLanguage: "en-US", // Fail rather than fall back.
    currency: "EUR",
  },
  frontend_url: "https://app.fcb-int.air-gmbh.com/",
  keycloak: {
    base: "https://id.fcb-int.air-gmbh.com/",
    authority: "auth/realms/air/protocol/openid-connect",
    wellknownEndpoint: "auth/realms/air/.well-known/openid-configuration",
    redirectUrl: "redirect",
    postLogoutRedirectUri: "login",
    clientId: "app-client",
    scope: "openid offline_access",
    responseType: "code",
    logLevel: LogLevel.Debug,
    ignoreNonceAfterRefresh: true,
  },
  graphql: {
    uri: "https://api.app.fcb-int.air-gmbh.com/graphql",
    wsUri: "wss://api.app.fcb-int.air-gmbh.com/graphql",
  },
  sentry: {
    reporting: false,
    dsn: "",
  },
  hubspot: {
    contactForm: {
      portalId: "26476080",
      formId: "5164623a-10b7-431d-8ed8-7f5fb4decada",
      emailFieldName: "email",
      companionTypeFormName: "companion",
    },
    meetingSrc: "https://meetings.hubspot.com/marvin-koenig?embed=true",
  },
  flags,
  debounceTime: 350,
  alertTime: 4500,
  loadingDebounce: 350,
  printDelay: 350,
  externalURLs: {
    AIRGmbh: "https://air-gmbh.com",
    imprint: "https://air-gmbh.com/legal/impressum/",
  },
};
